import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Button,
  Box,
  Typography,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControlLabel,
  InputAdornment,
  Paper,
  IconButton,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import googleads300 from '../../../assets/img/google-ads-300.png';
import Header from '../../components/Header/Header';
import SwitchToggle from '../../components/Switchtoggle/SwitchToggle';
import googleads980 from '../../../assets/img/google-ads-930.jpg';
import sunIcon from '../../../assets/img/sun-icon.svg';
import sunIconactive from '../../../assets/img/sun-icon-white.svg';
import partlysunny from '../../../assets/img/partly-sunny-icon.svg';
import partlysunnyactive from '../../../assets/img/partly-sunny-icon-white.svg';
import rainicon from '../../../assets/img/rain-icon.svg';
import rainiconactive from '../../../assets/img/rain-icon-white.svg';
import thunderstorm from '../../../assets/img/thunderstorm-icon.svg';
import thunderstormactive from '../../../assets/img/thunderstorm-icon-white.svg';
import '../../scss/Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import { authen, db, storage } from '../../firebase/firebase';
import getQuestionApi from '../../../../../../shared/Utils/GetQuestionApi/Api';
import { setFilter } from '../../../../../../shared/store/src/lib/actions/filter';
import { resetDetails } from '../../../../../../shared/store/src/lib/actions/questiondetails';
import { setQuestions } from '../../../../../../shared/store/src/lib/actions/question';

import { setBadge } from '../../../../../../shared/store/src/lib/actions/badges';
import { setLevelImage } from 'shared/store/src/lib/actions/levelsImage';
import { setStaticPages } from 'shared/store/src/lib/actions/staticPages';
import { useNavigate } from 'react-router-dom';
import 'firebase/storage';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { doc, getDoc } from '@firebase/firestore';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import { onAuthStateChanged } from 'firebase/auth';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import RemoteErrorLog from '../../Utils/api';
import { environment } from 'apps/spellingheroweb/src/environments/environment';
import SpellingPrefModal from './SpellingPrefModal';
import { useLocation } from 'react-router-dom';
import TrialExpiryModal from '../../components/TrialExpiryModal';
import { checkTrialExpiry } from '../../Utils/trialExpiryValidator';

export default function Home() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.userprofile);
  const filterData = useSelector((state: any) => state.questionfilter);
  const badgesUrl = useSelector((state: any) => state.badgeurls);
  const levelsUrl = useSelector((state: any) => state.levelImageUrl);
  const pagesUrl = useSelector((state: any) => state.pagesUrl);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [isRecom, setIsRecom] = useState(false);
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [userName, setUserName] = useState<any>();
  const [difficultyLevel, setDifficultyLevel] = useState<string>('Easy');
  const [anonymousUser, setAnonymousUser] = useState<boolean>();
  const [apiErrorMessage, setApiErrorMessage] = useState<string>();
  const [selection, setSelection] = useState(1);
  const [attainedBadgeUrl, setAttainedBadgeUrl] = useState('');
  const [user, setUser] = useState<any>(null);
  const [isTrialExpiryModalVisible, setIsTrialExpiryModalVisible] = useState(false);
  const phonogramEntries = profile?.user?.phonogram
    ? Object.entries(profile.user.phonogram)
    : [];
  phonogramEntries.sort(([keyA], [keyB]) => (keyA > keyB ? 1 : -1));

  const location = useLocation();
  // const [isSpellingPrefModalVisible, setIsSpellingPrefModalVisible] = useState(false);
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  const bubbleAudioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    bubbleAudioRef.current = new Audio('/assets/audio/bubble.mp3');
    bubbleAudioRef.current.preload = 'auto';
    bubbleAudioRef.current.load();

    return () => {
      if (bubbleAudioRef.current) {
        bubbleAudioRef.current.pause();
        bubbleAudioRef.current = null;
      }
    };
  }, []);

  const toggleRecom = () => {
    setIsRecom((previousState) => !previousState);
  };

  const toggleIncorrect = () =>
    setIsIncorrect((previousState) => !previousState);

  // useEffect(() => {
  //   if (location.state && location.state.showSpellingPrefModal) {
  //     setIsSpellingPrefModalVisible(true);
  //   }
  // }, [location.state]);

  useEffect(() => {
    const savedSoundPreference = localStorage.getItem('GlobalSoundsControl');
    setIsSoundEnabled(savedSoundPreference !== 'OFF');

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'GlobalSoundsControl') {
        setIsSoundEnabled(e.newValue !== 'OFF');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const playBubble = useCallback(() => {
    const currentSoundPreference = localStorage.getItem('GlobalSoundsControl');
    if (currentSoundPreference !== 'OFF' && bubbleAudioRef.current) {
      bubbleAudioRef.current.currentTime = 0;
      
      const playPromise = bubbleAudioRef.current.play();
      
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.warn('Error playing sound:', error);
        });
      }
    }
  }, []);

  const handleLevelSelection = (level: number, difficultyLevel: string) => {
    playBubble();
    setSelection(level);
    setDifficultyLevel(difficultyLevel);
  };

  const handleStartClick = () => {
    playBubble();
    getQuestions();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchAllData(currentUser?.uid);

        // setUserName(authen?.currentUser?.displayName);
        setAnonymousUser(authen?.currentUser?.isAnonymous);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (
      (badgesUrl.detail &&
        profile?.user?.phonogram &&
        attainedBadgeUrl !== '') === true
    ) {
      setIsLoading(false);
    }
    badgesUrl.detail &&
      setAttainedBadgeUrl(
        `${
          Object.values(badgesUrl.detail)[
            profile.user?.metrics?.badges_attained?.length - 1
          ]
        }`
      );
  }, [badgesUrl.detail, profile, attainedBadgeUrl]);

  useEffect(() => {
    if (filterData?.result?.level) {
      switch (filterData.result.level) {
        case 'Easy':
          setSelection(1);
          setDifficultyLevel('Easy');
          break;
        case 'Medium':
          setSelection(2);
          setDifficultyLevel('Medium');
          break;
        case 'Tough':
          setSelection(3);
          setDifficultyLevel('Tough');
          break;
        default:
          setSelection(4);
          setDifficultyLevel('Challenging');
      }
    }
  }, [filterData]);

  const fetchAllData = async (uid: string) => {
    try {
      await getUserProfile(uid);
      await fetchBadges();
      await fetchLevel();
      await fetchPages();
    } catch (error) {
      RemoteErrorLog(error, 'Home.tsx');
    }
  };

  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };

  const fetchBadges = async () => {
    if (badgesUrl.detail === null) {
      try {
        const storageRef = await ref(storage, 'web_images/badges');
        const { items } = await listAll(storageRef);

        const downloadPromises = items.map((item) => getDownloadURL(item));
        const urls = await Promise.all(downloadPromises);

        const badgeUrlsObject: any = {};
        urls.forEach((url, index) => {
          const fileName = items[index].name;
          badgeUrlsObject[fileName] = url;
        });
        dispatch(setBadge(badgeUrlsObject));
      } catch (e) {
        RemoteErrorLog(e, 'Home.tsx');
      } finally {
      }
    }
  };

  const fetchLevel = async () => {
    if (!levelsUrl.length) {
      try {
        try {
          const storageRef = await ref(storage, 'web_images/levels');
          const result_1 = await listAll(storageRef);
          const urls = await Promise.all(
            result_1.items.map((imageRef: any) => getDownloadURL(imageRef))
          );

          dispatch(setLevelImage(urls));
        } catch (e) {
          RemoteErrorLog(e, 'Home.tsx');
        }
      } finally {
      }
    }
  };
  const fetchPages = async () => {
    if (!pagesUrl.length) {
      try {
        try {
          const storageRef = await ref(storage, 'pages');
          const result_1 = await listAll(storageRef);
          const urls = await Promise.all(
            result_1.items.map((pages) => getDownloadURL(pages))
          );
          dispatch(setStaticPages(urls));
        } catch (e) {
          RemoteErrorLog(e, 'Home.tsx');
        }
      } finally {
      }
    }
  };

  const getUserProfile = async (uid: string) => {
    try {
      const docRef = doc(db, 'user_profile', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setUserProfile(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      RemoteErrorLog(error, 'Home.tsx');

      return null;
    }
  };

  const getQuestions = async () => {
    try {
      dispatch(resetDetails());
      setIsLoading(true);

      if (authen.currentUser) {
        const userProfileRef = doc(db, 'user_profile', authen.currentUser.uid);
        const userProfileSnap = await getDoc(userProfileRef);
        
        if (userProfileSnap.exists()) {
          const userData = userProfileSnap.data();

          const trialStatus = checkTrialExpiry(userData);
        
          if (trialStatus.expired) {
            setApiErrorMessage(trialStatus.message);
            setIsTrialExpiryModalVisible(true);
            setIsLoading(false);
            return;
          }
          
          const questionInfo = {
            uid: authen.currentUser?.uid,
            mode: isRecom === true ? 'RECOMMEND' : 'STANDARD',
            level: difficultyLevel,
            filter_type: '',
            filter_value: '',
            include_incorrect: isIncorrect,
          };
          if (searchQuery) {
            questionInfo.filter_type = 'CONTAINS_ANY';
            questionInfo.filter_value = searchQuery;
          }
          dispatch(setFilter(questionInfo));

        const result = await getQuestionApi(questionInfo, environment?.apiUrl);

        if (result == 'Error') {
          setIsQuestionModalVisible(true);
          setApiErrorMessage(
            'We’re currently experiencing some issues. Please try again later.'
          );
          setIsLoading(false);
        } else if (result.error_message !== null) {
          setIsLoading(false);
          setApiErrorMessage(result.error_message);
          setIsQuestionModalVisible(true);
        } else {
          dispatch(setQuestions(result?.words));
          setIsLoading(false);

            navigate('/quiz', { state: { navigated: true } });
          }
        }
      }
    } catch (error) {
      RemoteErrorLog(error, 'Home.tsx');
      setIsLoading(false);
    }
  };

  const getBorderColor = (correct: number, total: number) => {
    if (total === 0 || authen.currentUser?.isAnonymous) {
      return 'greynish-border';
    }

    const percentage = (correct / total) * 100;
    switch (true) {
      case percentage > 90 && percentage <= 100:
        return 'green-border';
      case percentage >= 70 && percentage <= 90:
        return 'greenish-border';
      case percentage >= 50 && percentage < 70:
        return 'pinkish-border';
      case percentage >= 0 && percentage < 50:
        return 'redish-border';
    }
  };
  return (
    <>
      <header>
        <Navbar currentPage="home" />
      </header>
      <Header currentPage="home" />
      <main className="page-bg overflow-hidden">
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
            {/* <Grid
              item
              lg={3}
              xs={12}
              sx={{ textAlign: 'center' }}
              className="order-item-2"
            > */}
            {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box> */}
            {/* </Grid> */}

            {/* <Box display="flex" justifyContent="center" marginY={2}>
            <img src={googleads980} alt="Google Ads" />
          </Box> */}
            <Grid item lg={7} xs={12} md={7}>
              {anonymousUser && (
                <section>
                  <Box
                    sx={{
                      padding: '8px',
                      marginY: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    className="create-box"
                  >
                    <Typography variant="h6">
                      Sign up for an account to track your progress,earn
                      badges,and unlock levels!
                    </Typography>

                    <Box
                      sx={{ display: 'flex', alignItems: 'center' }}
                      className="create-btn-home"
                    >
                      <Button
                        variant="text"
                        sx={{
                          color: '#fff',
                          textTransform: 'none',
                          textDecoration: 'underline',
                          fontSize: '20px',
                        }}
                        onClick={() => navigate('/login')}
                      >
                        Log in
                      </Button>
                      <Button
                        className="create-btn"
                        variant="contained"
                        sx={{
                          textTransform: 'none',
                          background: '#fff',
                          color: '#F68327',
                          padding: '6px',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => navigate('/signup')}
                      >
                        Create free account
                      </Button>
                    </Box>
                  </Box>
                </section>
              )}

              <section>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 0,
                    marginY: 3,
                    borderRadius: '12px',
                    boxShadow: 'none',
                  }}
                  className="challenge-step"
                >
                  <Typography variant="h1" gutterBottom>
                    Begin Challenge
                  </Typography>
                  <Box
                    marginBottom={2}
                    className="box-container home-container"
                  >
                    <Typography variant="h2">
                      Choose Difficulty Level
                    </Typography>
                    <Typography variant="body1" component="p">
                      Level up with each correct word
                    </Typography>

                    <section>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-around"
                        marginTop={2}
                        className="tabs-item"
                      >
                        <Box
                          textAlign="center"
                          sx={{ cursor: 'pointer', display: 'flex' }}
                          onClick={() => handleLevelSelection(1, 'Easy')}
                        >
                          <Box
                            className="home-list"
                            sx={{
                              background: selection == 1 ? 'green' : '',
                              color: selection == 1 ? 'white' : '',
                            }}
                          >
                            <img
                              src={selection == 1 ? sunIconactive : sunIcon}
                              alt="Easy"
                              className=""
                            />
                            <Typography
                              variant="body1"
                              className="correct-word"
                            >
                              Easy
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          textAlign="center"
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => handleLevelSelection(2, 'Medium')}
                        >
                          <Box
                            className="home-list"
                            sx={{
                              background: selection == 2 ? 'green' : '',
                              color: selection == 2 ? 'white' : '',
                            }}
                          >
                            <img
                              src={
                                selection == 2 ? partlysunnyactive : partlysunny
                              }
                              alt="Medium"
                            />
                            <Typography
                              variant="body1"
                              className="correct-word"
                            >
                              Medium
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          textAlign="center"
                          sx={{ cursor: 'pointer', display: 'flex' }}
                          onClick={() => handleLevelSelection(3, 'Tough')}
                        >
                          <Box
                            className="home-list"
                            sx={{
                              background: selection == 3 ? 'green' : '',
                              color: selection == 3 ? 'white' : '',
                            }}
                          >
                            <img
                              src={selection == 3 ? rainiconactive : rainicon}
                              alt="Tough"
                              className=""
                            />
                            <Typography
                              variant="body1"
                              className="correct-word"
                            >
                              Tough
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          textAlign="center"
                          sx={{ cursor: 'pointer', display: 'flex' }}
                          onClick={() => handleLevelSelection(4, 'Challenging')}
                        >
                          <Box
                            className="home-list"
                            sx={{
                              background: selection == 4 ? 'green' : '',
                              color: selection == 4 ? 'white' : '',
                            }}
                          >
                            <img
                              src={
                                selection == 4
                                  ? thunderstormactive
                                  : thunderstorm
                              }
                              alt="Challenging"
                              className=""
                            />
                            <Typography
                              variant="body1"
                              className="correct-word"
                            >
                              Challenging
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </section>
                  </Box>
                  <section>
                    <Box marginBottom={2} className="box-container">
                      <Typography variant="h2">
                        Personalize your search
                      </Typography>
                      <Box>
                        <FormControlLabel
                          className="form-switch"
                          label={
                            <Typography
                              fontSize="22px !important"
                              fontFamily="roboto"
                            >
                              Try Recommendation
                            </Typography>
                          }
                          control={
                            <SwitchToggle
                              value={isRecom}
                              setValue={toggleRecom}
                            />
                          }
                        />

                        <FormControlLabel
                          className="form-switch"
                          control={
                            <SwitchToggle
                              value={isIncorrect}
                              setValue={toggleIncorrect}
                            />
                          }
                          label={
                            <Typography
                              fontSize="22px !important"
                              fontFamily="roboto"
                            >
                              Include previous incorrect spellings
                            </Typography>
                          }
                        />
                      </Box>

                      <Accordion className="accordion-home">
                        <Box className="accordion-card">
                          <AccordionSummary
                            className="accordion-height"
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ padding: '0' }}
                          >
                            <Typography
                              fontSize="22px !important"
                              fontFamily="roboto"
                            >
                              What do you want to practice?
                            </Typography>
                          </AccordionSummary>
                        </Box>
                        <AccordionDetails>
                          <Typography
                            variant="h6"
                            gutterBottom
                            paddingBottom="23px"
                          >
                            Contains the specified letters
                          </Typography>
                          <Typography
                            className="font-18"
                            variant="body2"
                            color="#949494"
                            gutterBottom
                          >
                            “ch” represents the sound heard in words like
                            “chat”.
                          </Typography>
                          <TextField
                            className="home-textfield"
                            fullWidth
                            placeholder="Enter letters"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton edge="end">
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            sx={{ marginBottom: '30px', marginTop: '20px' }}
                          />
                          <Typography variant="h6" gutterBottom>
                            Top suggestions for searching words
                          </Typography>
                          <Box
                            sx={{ marginTop: '28px' }}
                            className="search-grid"
                          >
                            <Box className="search-word">
                              {phonogramEntries.map(
                                ([key, value]: any, index) => (
                                  <Paper
                                    elevation={1}
                                    sx={{
                                      padding: 1,
                                      textAlign: 'center',
                                      boxShadow: 'none',
                                    }}
                                    key={index}
                                    className="cursor-pointer"
                                  >
                                    <Box>
                                      <Typography
                                        variant="body1"
                                        component="div"
                                        onClick={() => setSearchQuery(key)}
                                        className={`box-inside-search font-24 font-600 ${getBorderColor(
                                          value.correct,
                                          value.total
                                        )}`}
                                      >
                                        {key}
                                        <Typography
                                          variant="caption"
                                          color="textSecondary"
                                          className="font-16 inside-word-color-border"
                                        >
                                          {' '}
                                          {!authen.currentUser?.isAnonymous ? (
                                            <>
                                              {' '}
                                              <span className="green">
                                                {' '}
                                                {value.correct}
                                              </span>
                                              /
                                              <span className="sky-blue">
                                                {value.total}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span className="green">-</span>/
                                              <span className="sky-blue">
                                                -
                                              </span>
                                            </>
                                          )}
                                        </Typography>
                                      </Typography>
                                    </Box>
                                  </Paper>
                                )
                              )}
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      {isLoading ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {' '}
                          <CircularProgress
                            size={56}
                            sx={{
                              color: 'primary.main',
                            }}
                          />{' '}
                        </Box>
                      ) : (
                        <Button
                          className="btn btn-primary br-100 btn-homepage"
                          variant="contained"
                          fullWidth
                          sx={{
                            marginTop: '20px',
                            padding: '6px',
                            fontSize: ' 24px',
                            fontWeight: '600',
                            fontFamily: 'Poppins',
                            textTransform: 'none',
                          }}
                          disabled={!authen?.currentUser}
                          onClick={handleStartClick}
                        >
                          Start
                        </Button>
                      )}
                    </Box>
                  </section>
                </Paper>
              </section>
            </Grid>

            {/* <Grid item lg={3} xs={12} sx={{ textAlign: 'center' }}> */}
            {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box> */}
            {/* </Grid> */}
          </Grid>
          {/* <Box display="flex" justifyContent="center" marginY={2}>
            <img src={googleads980} alt="Google Ads" />
          </Box> */}
        </Container>
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideNoQuestionModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />
        {/* <SpellingPrefModal
          open={isSpellingPrefModalVisible}
          onClose={() => setIsSpellingPrefModalVisible(false)}
        /> */}
        <TrialExpiryModal
          isModalVisible={isTrialExpiryModalVisible}
          onClose={() => setIsTrialExpiryModalVisible(false)}
          text={apiErrorMessage}
          modalTitle="Oops!"
          showMembershipOption={true}
          cancelButtonText="Cancel"
        />
      </main>
      <Footer />
    </>
  );
}
