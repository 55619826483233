import * as React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from 'react-router-dom';
import Landing from '../pages/Landing/Landing';

import Emailverify from '../pages/Emailverify/Emailverify';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import VerifyUser from '../pages/Emailverify/VerifyUser';
import LogIn from '../pages/LogIn/LogIn';
import SignUp from '../pages/SignUp/SignUp';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import RequireAuth from './RequireAuth';
import Home from '../pages/Home/Home';
import RequireAuthGuest from './RequireAuthGuest';
import LoggedIn from './LoggedIn';
import Quiz from '../pages/Quiz/Quiz';
import Result from '../pages/Result/Result';
import QuizResult from '../pages/Quiz/QuizResult';
import Profile from '../pages/Profile/Profile';
import TermsOfService from '../pages/TermsOfService/TermsOfService';
import AboutUs from '../pages/AboutUs/AboutUs';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import EditProfile from '../pages/EditProfile/EditProfile';
import DeleteAccount from '../pages/DeleteAccount/DeleteAccount';
import Score from '../pages/Score/Score';
import MyProgress from '../pages/MyProgress/MyProgress';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import Contactus from '../pages/Contactus/Contactus';
import CleverCallback from '../pages/Home/Callback'
import SchoolRegistration from '../pages/SignUp/SchoolRegestration';
import StudentSignup from '../pages/SignUp/StudentSignup';
import ResetPassword from '../pages/SignUp/PasswordReset';
import ManageAdmins from '../pages/SignUp/ManageAdmins';

const Routes = (props: any) => {
  return (
    <Switch>
      <Route path="/" element={<Landing />}></Route>
      <Route path="/auth/clever/callback" element={<CleverCallback />} />
      <Route
        path="/login"
        element={
          <LoggedIn>
            <LogIn />
          </LoggedIn>
        }
      ></Route>
      <Route
        path="/login/:schoolId"
        element={
          <LoggedIn>
            <LogIn />
          </LoggedIn>
        }
      ></Route>
      <Route
        path="/student-signup"
        element={
          <RequireAuthGuest>
            <StudentSignup />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/reset-password"
        element={
          <RequireAuthGuest>
            <ResetPassword />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/manage-admins"
        element={
          <RequireAuthGuest>
            <ManageAdmins />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/home"
        element={
          <RequireAuthGuest>
            <Home />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/quiz"
        element={
          <RequireAuthGuest>
            <Quiz />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/result"
        element={
          <RequireAuthGuest>
            <Result />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/quizResult"
        element={
          <RequireAuthGuest>
            <QuizResult />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/signup"
        element={
          <LoggedIn>
            <SignUp />
          </LoggedIn>
        }
      ></Route>
      <Route
        path="/register-school"
        element={
          <LoggedIn>
            <SchoolRegistration />
          </LoggedIn>
        }
      ></Route>
      <Route path="/email-verify" element={<Emailverify />}></Route>
      <Route path="/forgot-password" element={<ForgotPassword />}></Route>
      <Route path="/verify-user/:uid" element={<VerifyUser />}></Route>
      <Route
        path="/profile/:tabId?"
        element={
          <RequireAuthGuest>
            <Profile />
          </RequireAuthGuest>
        }
      ></Route>
      <Route path="/terms-of-service" element={<TermsOfService />}></Route>
      <Route path="/about-us" element={<AboutUs />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

      <Route
        path="/contact-us"
        element={
          <RequireAuthGuest>
            <Contactus />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/profile/edit"
        element={
          <RequireAuthGuest>
            <EditProfile />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/deleteAccount"
        element={
          <RequireAuth>
            <DeleteAccount />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/change-password"
        element={
          <RequireAuth>
            <ChangePassword />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/myProgress"
        element={
          <RequireAuthGuest>
            <MyProgress />
          </RequireAuthGuest>
        }
      ></Route>
      <Route
        path="/score"
        element={
          <RequireAuthGuest>
            <Score />
          </RequireAuthGuest>
        }
      ></Route>
      <Route path="*" element={<PageNotFound />}></Route>
    </Switch>
  );
};
export default Routes;
